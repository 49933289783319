<script>
  import { onMount } from 'svelte/internal';
  import { user } from './store.js';
  import { selectedModelStore } from './store.js';
  import Login from './Login.svelte';
  import Menu from './Menu.svelte';

  let _user;
  user.subscribe(value => {
	_user = value;
  });

	onMount(() => {
		window.onunhandledrejection = (e) => {
		  console.log('we got exception, but the app has crashed', e);
		  user.update((u) => (u = ''))
		}
		selectedModelStore.set(null);
	})

</script>
 
<main>
	<section class="main-app">
		{#if user && _user}
			<div class="panel--main">
				<div class="container--shadow">
					<Menu/>
				</div>
			</div>
		{:else}
			<div class="panel--additional panel--login">
				<div class="container--shadow">
					<Login/>
				</div>
			</div>
		{/if}
	</section>
</main>
import { writable } from 'svelte/store'

let persistedUser = localStorage.getItem('user')
export let user = writable(persistedUser ? JSON.parse(persistedUser) : '')
user.subscribe(u => localStorage.user = JSON.stringify(u))

let selectedModel = localStorage.getItem('selectedModel')
export let selectedModelStore = writable(selectedModel ? JSON.parse(selectedModel) : null)
selectedModelStore.subscribe(m => localStorage.selectedModel = JSON.stringify(m))

//let selectedTheme = localStorage.getItem('selectedTheme')
let selectedTheme = false;
export let selectedThemeStore = writable(Boolean(selectedTheme))
selectedThemeStore.subscribe(m => localStorage.selectedTheme = m)

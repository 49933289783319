﻿<script>
	import { onMount, onDestroy, createEventDispatcher } from 'svelte/internal';
	import { selectedModelStore } from './store.js';
	import { selectedThemeStore} from './store.js';
	import { t, locale, locales, language } from "./i18n";

	import Chat from './Chat.svelte';
	import { user } from './store.js';
	let _user;
	user.subscribe(value => {
		_user = value;
	});
	let _language = language;
	locale.subscribe(value => {
		_language = value;
		getModels();
	});
	let models = [];
	let selectedModelId = null;

	let selectedModel;
	selectedModelStore.subscribe(value => { selectedModel = value; });

	async function getModels() {
		const resp = await fetch('api/models?language=' + _language, {
			method: 'GET',
			headers: {
				'Authorization': `Bearer ${_user.token}`
			}
		})
		
		if (resp.ok){
			models = await resp.json();
			selectedModelId = selectedModel?.id;
		} else {
			throw new Error("Error");
		}
	}

	onMount(async () => {
		await getModels();
		//toggleTheme();
	});

	function selectModel(id) {
		selectedModelStore.set(models?.find(m => m.id == id) ?? null);
	}

	function toggleTheme() {
		if (selectedTheme == true) {
			document.body.classList.add('theme--dark');
		} else {
			document.body.classList.remove('theme--dark');
		}
		selectedThemeStore.set(selectedTheme);
	}

	let selectedTheme;
	selectedThemeStore.subscribe(value => { selectedTheme = value; });

</script>

<div class="custom-container flex-column">
	{#if selectedModel === null}
	<div class="landing-container">
		<header>
			<div class="logo-container">
				<img alt="BridgeLT Logo" src="assets/img/logo.png" width="130px" height="40px">
			</div>
		</header>
	</div>

	<div class="landing-container">
		<h1>
			{$t("menu.title")}
		</h1>
		<h3>
			{$t("menu.description")}
		</h3>

		<div class="charasters">
			{#each models as model}
				<div class="charaster" on:click={selectModel(model.id)}>
					<div class="charaster__title">
						<div class="{model.class}"></div>
						<div>
							<div class="title">
								{model.title}
							</div>
						</div>
					</div>
					<div class="description-text">
						{@html model.description}
					</div>
				</div>	
			{/each}

		</div>
	</div>

	<footer>

		<div class="d-flex justify-content-center">
			<div class="d-flex justify-content-between align-items-center mb-3" style="padding-right: 20px;">
				<!--<label class="pe-2">{$t("login.theme")}:</label>
				<div class="toggle-switch">
					<label>
						<input id="theme-checkbox" type="checkbox" bind:checked={selectedTheme} on:change={toggleTheme} >
						<span class="slider"></span>
					</label>
				</div>-->
			</div>
			<p>
				<select class="form-select" bind:value={$locale}>
				  {#each locales as l}
					<option value={l}>{l}</option>
				  {/each}
				</select>
			</p>
		</div>


		<div class="landing-container">
			<div>
				Created with <img alt="" style="height: 11px;"
								  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAaCAMAAAB4vXe4AAAAA3NCSVQICAjb4U/gAAAAAXNSR0IArs4c6QAAAFFQTFRFAAAA/0Ag90gg9EUg80Qk8kQk8kYj9EYj9EUj9EUi9EQi80Yk80Yk80Uj8kUj8kYj80Yj80Yj80Uk80Uk80Uk80Yk8kYj8kUj8kUj8kUj80YkFJ1m+QAAABp0Uk5TABAgMEBPUF9gb3B/gJCfoK+wv8DP0N/g7/DVI8WiAAAAx0lEQVQoz33S227DIBBF0Q24lLi4DvENn///0D6k8SUBz9tobWkkBAD25yHl+zcAmNsja/794jUx6zmzO26je3IvDd7g2kUK5i4l72wTV2UHELUGtnLW2vyf6ZUN2KywXeql1W3boARRA/t0y85YyTLIU5ukFslWPapD4sITi9yFR5Laqk8KBM01biQDi7oym1ER8Do80JknAxCKgRm3pywFBy4FJ/4M3vg9+OBzUOBjUOQ9qPArqDIE6XbBECRdMPhJ6fyd/gAZLRDP6QJjKAAAAABJRU5ErkJggg==" />
				by Bridge Learning Tech
			</div>
		</div>
	</footer>
	{:else}
		<Chat bind:selectedModel/>
	{/if}
</div>
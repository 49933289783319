<script>
	import { t, locale, locales } from "./i18n";

	export let status;
	export let points;
	import StageBubble from './StageBubble.svelte';
	let greet = 'Greet and Welcome';
	let info = 'Information Gathering';
	let present = 'Presentation';
	let final = 'Finalize the sale';
	let active = 'active';
	let inactive = 'inactive';
	let possition = 0;

	$: {
		if (points !== undefined) {
			console.log(points);
			possition = points * 5;
			console.log(possition);
		}
	}
</script>

<div class="stage-bar">

	{#if status === undefined}
	<div class="status inactive">
		<div class="bike"></div>
		<div class="inactive-animatioon"></div>
	</div>
	
	{:else if status.trim() === 'In Progress'}
	<div class="status">
		<div class="bike" style="padding-left:{possition}px;"></div>
		<div class="active-animatioon"></div>
		<div style="width:100%; text-align:center;">In Progress...</div>
	</div>

	{:else if status.trim() === 'Completed'}
	<div class="status completed">
		<div class="bike"></div>
		<div class="inactive-animatioon completed"></div>
		Completed
	</div>
	

	{:else if status.trim() === 'Failed'}
	<div class="status failed">
		<div class="bike"></div>
		<div class="inactive-animatioon failed"></div>
		Failed, Try Again
	</div>

	{/if}
	
	
</div>

<style>
	.stage-bar {
	width: 80%;
	height: 50px;
	background-color: rgba(0,0,0,0);
	padding: 10px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	}
	.connector {
	height: 5px;
	flex-grow: 1;
	transition: background-color 0.3s;
	}
	.active {
	background-color: orange;
	}
	.inactive {
	background-color: #3b3a3a;
	}
</style>
